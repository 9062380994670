<template>
  <div class="user-details">
    <div v-if="userDetails">
      <PageMenu :breadcrumbs="breadcrumbs" :page="page">
        <template v-slot:toolbar>
          <b-button v-if="userDetails.parentId !== null" variant="light" size="sm" class="mr-2"
            :to='{name: "users.user.details", params: {userId: userDetails.parentId}}'>
            &laquo; back
          </b-button>

          <b-button variant="danger" size="sm" v-b-modal.modal-delete-user>
            <BIconX/> Delete
          </b-button>
          <b-modal ref="modalDeleteUser" id="modal-delete-user" title="Delete user" hide-footer>
              Are you sure that you want to delete this user and all his subaccounts?
              <b-button class="mt-3" variant="danger" size="sm" @click="deleteUser()">Yes, delete user!</b-button>
          </b-modal>
        </template>
      </PageMenu>

      <div class="page-with-menu">
        <b-card no-body>
          <b-tabs card lazy>
            <b-tab title="Details" active>
              <b-card-text><UserDetails :userDetails="userDetails" @user-updated="onUserUpdated"/></b-card-text>
            </b-tab>
            <b-tab title="Password">
              <b-card-text><UserPassword :userDetails="userDetails"/></b-card-text>
            </b-tab>
            <b-tab title="Roles &amp; Permissions">
              <b-card-text><UserRolesPermissions :userDetails="userDetails"/></b-card-text>
            </b-tab>
            <b-tab v-if="userDetails.parentId === null" title="Subaccounts" @click="getUsers({pageNo: 1})">
              <b-card-text no-body>
                <b-button v-if="user.hasPermission('users:user.create')" variant="primary" size="sm" class="mb-3" v-b-modal.modal-new-user>
                  <BIconPersonPlus/> New Sub-Account
                </b-button>
                <b-modal v-if="user.hasPermission('users:user.create')" id="modal-new-user" title="Create new sub-account" hide-footer>
                    <UserCreate @user-created="subaccountCreated" :parentUser="userDetails"/>
                </b-modal>

                <UserFilters @on-search="applyFilters" class="mb-3" showParentsFilters="no"/>

                <UserList class="mt-3" :table="table" :paginationRouter="paginationRouter"/>
              </b-card-text>
            </b-tab>

            <b-tab title="Channels">
              <UserChannels :userDetails="userDetails"/>
            </b-tab>
            <!--
            <b-tab title="Widgets">
              <UserWidgets :userDetails="userDetails"/>
            </b-tab>
          -->
          </b-tabs>
        </b-card>


      </div>
    </div>

  </div>
</template>

<script>
import {UserDetails,UserPassword,UserList,UserRolesPermissions, UserFilters,UserCreate,UserChannels} from './../../components/users';
import moment from 'moment';
import PageMenu from '@/common/components/PageMenu';
import {BIconX,BIconPersonPlus} from 'bootstrap-vue';
export default {
  props: ['user'],
    components: {
      PageMenu,
      BIconX,BIconPersonPlus,
      UserDetails,UserPassword,UserList,UserRolesPermissions,UserFilters,UserCreate,
      UserChannels
    },
    data(){
      return {
        page: {
            title: "User Details",
        },
        breadcrumbs: [
            {
                title: "Dashboard",
                url: this.$router.resolve({name: "app.dashboard"}).href
            },
            {
                title: "Browse Users",
                url: this.$router.resolve({name: "users"}).href
            },
            {
                title: "User Details"
            }
        ],
        userDetails: null,
        table: {
          isLoading: false,
          records: [],
          pagination: {
            currentPageNo: 1
          },
          fields: [
            {
              key: 'createdAt',
              label: "Joined",
              formatter: (value) => {
                return moment(value).format('DD/MM/YYYY');
              }
            },
            {
              key: 'name',
              label: "Name"
            },
            {
              key: 'email',
              label: "E-Mail"
            },
            {
              key: 'roles',
              label: "Role",
            },
            {
              key: 'status',
              label: "Status"
            },
            {
              key: 'locale',
              label: "Language"
            },
            {
              key: 'options',
              label: ""
            },
          ]
        }
      }
    },
    methods: {
      subaccountCreated(){
        this.getUsers({pageNo: 1});
      },
      deleteUser(){
        this.$api.delete(`user/${this.userDetails.id}`).then(() => {
          if(this.userDetails.parentId){
            this.$router.push({name: "users.user.details", params:{userId: this.userDetails.parentId}});
          }
          else{
            this.$router.push({name: "users"});
          }
        })
      },
      paginationRouter(pageNum){
        let query =  {};
        if(pageNum > 1){
            query.page = pageNum;
        }

        return this.$router.resolve({name: 'users',query: query}).href;
      },

      getName(){
        let arr = [];
        if(this.userDetails.firstName){
          arr.push(this.userDetails.firstName);
        }
        if(this.userDetails.lastName){
          arr.push(this.userDetails.lastName);
        }

        if(arr.length === 0){
          return this.userDetails.email;
        }

        return arr.join(' ');
      },

      getUsers({pageNo}, filters){
        this.table.isLoading = true;
        this.table.records = [];
        this.$api.get('users',{
          params: Object.assign({
            perPage: 50,
            page: pageNo,
            orderBy: "datetime",
            arrange: "desc",
            parentId: this.userDetails.id
          },filters || {})
        }).then(({data}) => {
          this.table.isLoading = false;
          this.table.records = data.records.filter(o => o.id !== this.userDetails.id);
          this.table.pagination = data.pagination;
        });
      },

      getUser(userId){
        this.$api.get(`user/${userId}`).then((res) => {
          this.userDetails = res.data;
          this.page.title = this.getName();
        });
      },

      onUserUpdated(data){
        this.userDetails = data;
        this.page.title = this.getName();
      },

      applyFilters(filters){
        this.getUsers({pageNo: 1}, filters);
      }
    },
    mounted(){
      this.getUser(this.$route.params.userId);
      this.$emit('set-sidebar',{active: "users"});
    },

    watch: {
      "$route.params.userId": function(userId){
        this.userDetails = null;
        this.getUser(userId);
      }
    }
};
</script>
